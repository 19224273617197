
// @ts-nocheck


export const localeCodes =  [
  "en",
  "fr"
]

export const localeMessages = {
  "en": [],
  "fr": []
}

export const vueI18nConfigs = [
  () => import("../i18n.config.ts?hash=bffaebcb&config=1" /* webpackChunkName: "i18n_config_bffaebcb" */)
]

export const nuxtI18nOptions = {
  "experimental": {
    "localeDetector": ""
  },
  "bundle": {
    "compositionOnly": true,
    "runtimeOnly": false,
    "fullInstall": true,
    "dropMessageCompiler": false
  },
  "compilation": {
    "jit": true,
    "strictMessage": true,
    "escapeHtml": false
  },
  "customBlocks": {
    "defaultSFCLang": "json",
    "globalSFCScope": false
  },
  "vueI18n": "",
  "locales": [
    "en",
    "fr"
  ],
  "defaultLocale": "fr",
  "defaultDirection": "ltr",
  "routesNameSeparator": "___",
  "trailingSlash": false,
  "defaultLocaleRouteNameSuffix": "default",
  "strategy": "prefix_except_default",
  "lazy": false,
  "langDir": null,
  "rootRedirect": null,
  "detectBrowserLanguage": {
    "alwaysRedirect": false,
    "cookieCrossOrigin": false,
    "cookieDomain": null,
    "cookieKey": "i18n_redirected",
    "cookieSecure": false,
    "fallbackLocale": "",
    "redirectOn": "root",
    "useCookie": true
  },
  "differentDomains": false,
  "baseUrl": "",
  "dynamicRouteParams": false,
  "customRoutes": "config",
  "pages": {
    "auth/index": {
      "fr": "/connexion",
      "en": "/auth"
    },
    "auth/login": {
      "fr": "/connexion/se-connecter",
      "en": "/auth/login"
    },
    "auth/signup": {
      "fr": "/connexion/s-inscrire",
      "en": "/auth/signup"
    },
    "game-1/index": {
      "fr": "/le-jeu",
      "en": "/the-game"
    },
    "game-1/jslots": {
      "fr": "/le-jeu/roulette",
      "en": "/the-game/slot-machine"
    },
    "game-1/your-gift": {
      "fr": "/le-jeu/mon-gain",
      "en": "/the-game/your-gift"
    },
    "game-2/index": {
      "fr": "/le-jeu-mechamment-gourmand",
      "en": "/the-wickedly-greedy-game"
    },
    "game-2/result": {
      "fr": "/le-jeu-mechamment-gourmand/resultat",
      "en": "/the-wickedly-greedy-game/result"
    },
    "game-2/winner": {
      "fr": "/le-jeu-mechamment-gourmand/bravo",
      "en": "/the-wickedly-greedy-game/win"
    },
    "game-2/loser": {
      "fr": "/le-jeu-mechamment-gourmand/dommage",
      "en": "/the-wickedly-greedy-game/lose"
    },
    "game-3/index": {
      "fr": "/le-jeu-mechamment-gourmand-3",
      "en": "/the-wickedly-greedy-game-3"
    },
    "game-3/result": {
      "fr": "/le-jeu-mechamment-gourmand-3/resultat",
      "en": "/the-wickedly-greedy-game-3/result"
    },
    "game-3/winner": {
      "fr": "/le-jeu-mechamment-gourmand-3/bravo",
      "en": "/the-wickedly-greedy-game-3/win"
    },
    "game-3/loser": {
      "fr": "/le-jeu-mechamment-gourmand-3/dommage",
      "en": "/the-wickedly-greedy-game-3/lose"
    },
    "profile/addresses/index": {
      "fr": "/mon-compte/mes-adresses",
      "en": "/my-account/my-addresses"
    },
    "profile/addresses/new": {
      "fr": "/mon-compte/mes-adresses/nouvelle-adresse",
      "en": "/my-account/my-addresses/new-address"
    },
    "profile/history/proof-of-purchase/[uuid]": {
      "fr": "/mon-compte/mon-historique/ma-preuve-d-achat/[uuid]",
      "en": "/my-account/my-history/my-proof-of-purchase/[uuid]"
    },
    "profile/history/declarations": {
      "fr": "/mon-compte/mon-historique/mes-preuves-d-achat",
      "en": "/my-account/my-history/my-proofs-of-purchase"
    },
    "profile/history/orders": {
      "fr": "/mon-compte/mon-historique/mes-commandes",
      "en": "/my-account/my-history/my-orders"
    },
    "profile/index": {
      "fr": "/mon-compte",
      "en": "/my-account"
    },
    "profile/infos": {
      "fr": "/mon-compte/mon-profil",
      "en": "/my-account/my-profile"
    },
    "profile/security": {
      "fr": "/mon-compte/connexion-et-securite",
      "en": "/my-account/connection-and-security"
    },
    "shop/goodies/[id]/[slug]": {
      "fr": "/la-grande-boutique/cadeau/[id]/[slug]",
      "en": "/the-big-shop/gift/[id]/[slug]"
    },
    "shop/basket": {
      "fr": "/la-grande-boutique/panier",
      "en": "/the-big-shop/basket"
    },
    "shop/declaration": {
      "fr": "/la-grande-boutique/declarer-mes-achats",
      "en": "/the-big-shop/declare-my-purchases"
    },
    "shop/index": {
      "fr": "/la-grande-boutique",
      "en": "/the-big-shop"
    },
    "shop/order-informations": {
      "fr": "/la-grande-boutique/commande/informations",
      "en": "/the-big-shop/order/information"
    },
    "shop/order-summary": {
      "fr": "/la-grande-boutique/commande/recapitulatif",
      "en": "/the-big-shop/order/summary"
    },
    "contact": {
      "fr": "/contact",
      "en": "/contact"
    },
    "eligible-products": {
      "fr": "/produits-eligibles",
      "en": "/eligible-products"
    },
    "faq": {
      "fr": "/faq",
      "en": "/faqs"
    },
    "how-to-participate": {
      "fr": "/comment-participer",
      "en": "/how-to-participate"
    },
    "legal-notices": {
      "fr": "/mentions-legales",
      "en": "/legal-notices"
    },
    "reset-password": {
      "fr": "/reinitialiser-son-mot-de-passe",
      "en": "/reset-my-password"
    },
    "shop-conditions": {
      "fr": "/conditions-de-la-boutique",
      "en": "/shop-conditions"
    },
    "shop-limited": {
      "fr": "/la-grande-boutique-hors-connexion",
      "en": "/the-big-shop-not-logged"
    },
    "show": {
      "fr": "/show",
      "en": "/show"
    }
  },
  "skipSettingLocaleOnNavigate": false,
  "types": "composition",
  "debug": false,
  "parallelPlugin": false,
  "i18nModules": []
}

export const nuxtI18nOptionsDefault = {
  "experimental": {
    "localeDetector": ""
  },
  "bundle": {
    "compositionOnly": true,
    "runtimeOnly": false,
    "fullInstall": true,
    "dropMessageCompiler": false
  },
  "compilation": {
    "jit": true,
    "strictMessage": true,
    "escapeHtml": false
  },
  "customBlocks": {
    "defaultSFCLang": "json",
    "globalSFCScope": false
  },
  "vueI18n": "",
  "locales": [],
  "defaultLocale": "",
  "defaultDirection": "ltr",
  "routesNameSeparator": "___",
  "trailingSlash": false,
  "defaultLocaleRouteNameSuffix": "default",
  "strategy": "prefix_except_default",
  "lazy": false,
  "langDir": null,
  "rootRedirect": null,
  "detectBrowserLanguage": {
    "alwaysRedirect": false,
    "cookieCrossOrigin": false,
    "cookieDomain": null,
    "cookieKey": "i18n_redirected",
    "cookieSecure": false,
    "fallbackLocale": "",
    "redirectOn": "root",
    "useCookie": true
  },
  "differentDomains": false,
  "baseUrl": "",
  "dynamicRouteParams": false,
  "customRoutes": "page",
  "pages": {},
  "skipSettingLocaleOnNavigate": false,
  "types": "composition",
  "debug": false,
  "parallelPlugin": false
}

export const nuxtI18nInternalOptions = {
  "__normalizedLocales": [
    {
      "iso": "en",
      "code": "en"
    },
    {
      "iso": "fr",
      "code": "fr"
    }
  ]
}

export const NUXT_I18N_MODULE_ID = "@nuxtjs/i18n"
export const parallelPlugin = false
export const isSSG = false

