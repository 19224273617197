import { useGoTo } from 'vuetify'
import { sumBy } from 'lodash-es'

import { GENERIC_ERROR_TEXT } from '@/stores/snackbar'
import type { IAddress } from '@/utils/types/core/address'
import { UPLOAD_MAX_SIZE } from '@/utils/constants'

const KEY_FOR_BACKPACK = 'backpack'
const KEY_FOR_ERASER = 'eraser'
const KEY_FOR_NOTEPAD = 'notepad'
const KEY_FOR_TRIP = 'trip'

interface IBenefit {
  id: number
  name: string
  image_url: string
  is_proof_of_purchase_needed: boolean
  requested_fields: null | string[]
  key_for_display: null | string
}

interface ICodeDetails {
  code_id: number
  code: string
  has_won: boolean
  proof_of_purchase_needed: null | boolean
  user_information_needed: boolean
  s3_upload_image_path: string
  benefit: null | IBenefit
  validated_at: null | string
  validation_denied_at: null | string
  denial_reason: null | boolean
  distributed_at: null | string
  success?: true
}

export const useStoreGame3 = defineStore('game3', () => {
  const nuxtApp = useNuxtApp()
  const router = useRouter()
  const localePath = useLocalePath()
  const goTo = useGoTo()
  const auth = useStoreAuth()
  const snackBar = useStoreSnackbar()

  const isModalOpenProofOfPurchaseNeeded = ref(true)

  const code = ref('')
  // const codeErrorMessage = ref<null | string>(null)
  const isBurningCode = ref(false)

  const receipts = ref<File[][]>([])
  const termsAccepted = ref(false)

  const noReceipt = computed(() => receipts.value.length === 0)
  // const isCodeFilled = computed(() => !!code.value)
  const receiptsContentSize = computed(() =>
    sumBy(receipts.value, (files) => sumBy(files, (file) => file.size)),
  )
  const receiptsAreTooBig = computed(
    () => receiptsContentSize.value >= UPLOAD_MAX_SIZE,
  )
  const canCodeBeValidated = computed(
    () =>
      termsAccepted.value &&
      // isCodeFilled.value &&
      !noReceipt.value &&
      !receiptsAreTooBig.value &&
      true,
  )

  const codeDetails = ref<ICodeDetails | null>(null)
  const codeIsLoaded = ref(false)

  const isResultDiscovered = ref(false)

  const giftName = computed(() => {
    if (!codeDetails.value) return
    if (!codeDetails.value.benefit) return

    switch (codeDetails.value.benefit.key_for_display) {
      case KEY_FOR_BACKPACK:
        return 'un sac à dos Minions et un porte-clé Gnocchi'
      case KEY_FOR_ERASER:
        return 'un lot de 3 gommes Minions et un porte-clé Gnocchi'
      case KEY_FOR_NOTEPAD:
        return 'un carnet de notes Minions et un porte-clé Gnocchi'
      case KEY_FOR_TRIP:
        return 'un voyage paradisiaque'
    }
  })
  const giftIsSingular = computed(
    () => codeDetails.value?.benefit?.key_for_display === KEY_FOR_TRIP,
  )
  const stepValue = ref(1)

  const userTitle = ref('')
  const userLastName = ref('')
  const userFirstName = ref('')
  const userEmail = ref('')
  const userPhoneNumber = ref('')

  const userAddressName = ref('')
  const userAddress1 = ref('')
  const userAddress2 = ref('')
  const userPostalCode = ref('')
  const userCity = ref('')

  const userAddressId = ref<null | number>(null)
  const saveAddressOnProfile = ref(false)

  const isParticipationUncomplete = ref(false)
  const isSubmittingParticipation = ref(false)
  const isParticipationCompleted = ref(false)

  function $reset() {
    // do not reset isModalOpenProofOfPurchaseNeeded here
    // cause it prevents scrolling in playAgain
    // isModalOpenProofOfPurchaseNeeded.value = true

    code.value = 'nothing'
    // codeErrorMessage.value = ''
    isBurningCode.value = false

    receipts.value = []
    termsAccepted.value = false

    codeDetails.value = null
    codeIsLoaded.value = false

    isResultDiscovered.value = false

    stepValue.value = 1

    userTitle.value = ''
    userLastName.value = ''
    userFirstName.value = ''
    userEmail.value = ''
    userPhoneNumber.value = ''

    userAddressName.value = ''
    userAddress1.value = ''
    userAddress2.value = ''
    userPostalCode.value = ''
    userCity.value = ''

    userAddressId.value = null
    saveAddressOnProfile.value = false

    isParticipationUncomplete.value = false
    isSubmittingParticipation.value = false
    isParticipationCompleted.value = false
  }

  async function playAgain() {
    isModalOpenProofOfPurchaseNeeded.value = false
    await router.push(localePath('game-3'))
    $reset()
    await goTo(0)
    isModalOpenProofOfPurchaseNeeded.value = true
  }

  function addReceiptWithFiles($event: Event, handleChange: Function) {
    const input = $event.target as HTMLInputElement

    const newFiles = input.files!
    if (newFiles.length === 0) return

    const filesToAdd: File[] = []
    for (const file of newFiles) {
      filesToAdd.push(file)
    }
    receipts.value.push(filesToAdd)

    input.value = ''

    handleChange(receipts.value, true)
  }
  function addFilesToReceipt(
    {
      receiptIndex,
      files,
    }: {
      receiptIndex: number
      files: File[]
    },
    handleChange: Function,
  ) {
    receipts.value[receiptIndex].push(...files)
    handleChange(receipts.value, true)
  }
  function removeFileFromReceipt(
    {
      receiptIndex,
      fileIndex,
    }: {
      receiptIndex: number
      fileIndex: number
    },
    handleChange: Function,
  ) {
    receipts.value[receiptIndex].splice(fileIndex, 1)

    if (receipts.value[receiptIndex].length === 0) {
      receipts.value.splice(receiptIndex, 1)
    }

    handleChange(receipts.value, true)
  }

  const burnCode = async () => {
    if (isBurningCode.value) return

    isBurningCode.value = true
    // codeErrorMessage.value = null

    let response
    try {
      response = await nuxtApp.$PE.client.burnUniqCode({
        code: code.value,
      })
    } catch (error) {
      nuxtApp.$airbrakeNotify({
        error,
        context: { locator: 'burnCode #1' },
      })
      snackBar.addToastWithGenericError()
      // this.addToast({ text: GENERIC_ERROR_TEXT })
      // codeErrorMessage.value = GENERIC_ERROR_TEXT
      isBurningCode.value = false
      return
    }

    if (response.error) {
      let text
      switch (response.error.code) {
        case 'locked':
          text = 'Trop de demandes simultanées'
          break
        case 'winner_locked':
          text = 'Une autre requête est déjà en cours de traitement'
          break
        // case 'not_existing':
        //   codeErrorMessage.value = 'Code invalide'
        //   break
        // case 'not_accessible':
        //   codeErrorMessage.value = 'Code non valide'
        //   break
        // case 'already_burned':
        //   codeErrorMessage.value = 'Code déjà utilisé'
        //   break
        // case 'empty':
        //   codeErrorMessage.value = "Aucun code n'a été indiqué"
        //   break
        // case 'too_much_time_elapsed_since_code_input':
        //   codeErrorMessage.value =
        //     'Vous avez rentré ce code il y a trop longtemps, il a expiré'
        //   break
        default:
          nuxtApp.$airbrakeNotify({
            error: new Error('unhandled error code from backend'),
            params: { response },
            context: { locator: 'burnCode #2' },
          })
          text = GENERIC_ERROR_TEXT
      }
      snackBar.addToast({ text })
      isBurningCode.value = false
      return
    }

    codeDetails.value = response
    codeIsLoaded.value = true

    const shouldStop = await submitReceiptAndLinkItToCode()
    isBurningCode.value = false
    if (shouldStop) return

    router.push(localePath('game-3-result'))
  }

  async function submitReceiptAndLinkItToCode() {
    let response
    try {
      response = await nuxtApp.$PE.client.uploadWithFiles({
        files: receipts.value[0],
      })
    } catch (error) {
      snackBar.addToastWithGenericError()
      nuxtApp.$airbrakeNotify({
        error,
        context: {
          locator: 'submitReceiptAndLinkItToCode -> PE.client.uploadWithFiles',
        },
      })
      return true
    }

    const receiptKitUuid = response

    if (!codeDetails.value!.has_won) return

    try {
      response = await nuxtApp.$PE.client.linkCodeToReceipt({
        codeId: codeDetails.value!.code_id,
        receiptKitUuid,
      })
    } catch (error) {
      snackBar.addToastWithGenericError()
      nuxtApp.$airbrakeNotify({
        error,
        context: {
          locator:
            'submitReceiptAndLinkItToCode -> PE.client.linkCodeToReceipt',
        },
      })
      return true
    }

    if (!response.success) {
      isSubmittingParticipation.value = false
      snackBar.addToastWithGenericError()
      nuxtApp.$airbrakeNotify({
        error: new Error(
          'badrequest for submitReceiptAndLinkItToCode -> PE.client.linkCodeToReceipt',
        ),
        params: { response },
        context: {
          locator:
            'submitReceiptAndLinkItToCode -> PE.client.linkCodeToReceipt',
        },
      })
      return true
    }
  }

  function makeSureCodeIsLoadedOrRestart() {
    // if (codeIsLoaded.value) return
    // playAgain()
  }

  function discoverResult() {
    if (isResultDiscovered.value) return

    isResultDiscovered.value = true

    setTimeout(async () => {
      await router.push(
        localePath(
          codeDetails.value?.has_won ? 'game-3-winner' : 'game-3-loser',
        ),
      )
    }, 2000)
  }
  function discoverResultAfterSomeTime() {
    setTimeout(discoverResult, 3000)
  }

  function updateUserInfoFromAuth() {
    userTitle.value = auth.user?.title ? auth.user.title : ''
    userLastName.value = auth.user?.lastName ? auth.user.lastName : ''
    userFirstName.value = auth.user?.firstName ? auth.user.firstName : ''
    userEmail.value = auth.user?.email ? auth.user.email : ''
    userPhoneNumber.value = auth.user?.phone ? auth.user.phone : ''
  }
  function updateAddressDetails(address: IAddress) {
    userAddressName.value = address.user_defined_identifier
    userAddress1.value = address.address_1
    userAddress2.value = address.address_2
    userCity.value = address.city
    userPostalCode.value = address.postal_code
  }

  function goToStep1() {
    stepValue.value = 1
    isParticipationUncomplete.value = true
  }

  async function submitParticipation() {
    if (isSubmittingParticipation.value) return

    isSubmittingParticipation.value = true

    let response
    try {
      response = await nuxtApp.$PE.client.uploadWithFiles({
        files: receipts.value[0],
      })
    } catch (error) {
      isSubmittingParticipation.value = false
      snackBar.addToastWithGenericError()
      nuxtApp.$airbrakeNotify({
        error,
        context: {
          locator: 'submitParticipation -> PE.client.uploadWithFiles',
        },
      })
      return
    }

    const receiptKitUuid = response

    try {
      response = await nuxtApp.$PE.client.linkCodeToUserInformation({
        codeId: codeDetails.value!.code_id,
        receiptKitUuid,
        userInformationFields: {
          user_defined_identifier: saveAddressOnProfile.value
            ? userAddressName.value
            : null,
          title: userTitle.value,
          last_name: userLastName.value,
          first_name: userFirstName.value,
          email: userEmail.value,
          phone_number: userPhoneNumber.value,
          address_1: userAddress1.value,
          address_2: userAddress2.value,
          postal_code: userPostalCode.value,
          city: userCity.value,
        },
      })
    } catch (error) {
      isSubmittingParticipation.value = false
      snackBar.addToastWithGenericError()
      nuxtApp.$airbrakeNotify({
        error,
        context: {
          locator: 'submitParticipation -> PE.client.linkCodeToUserInformation',
        },
      })
      return
    }

    if (!response.success) {
      isSubmittingParticipation.value = false
      snackBar.addToastWithGenericError()
      nuxtApp.$airbrakeNotify({
        error: new Error(
          'badrequest for submitParticipation -> PE.client.linkCodeToUserInformation',
        ),
        params: { response },
        context: {
          locator: 'submitParticipation -> PE.client.linkCodeToUserInformation',
        },
      })
      return
    }

    isSubmittingParticipation.value = false
    isParticipationCompleted.value = true
  }

  return {
    KEY_FOR_BACKPACK,
    KEY_FOR_ERASER,
    KEY_FOR_NOTEPAD,
    KEY_FOR_TRIP,

    $reset,

    playAgain,

    isModalOpenProofOfPurchaseNeeded,

    code,
    // codeErrorMessage,

    receipts,
    termsAccepted,

    noReceipt,
    // isCodeFilled,
    receiptsAreTooBig,
    canCodeBeValidated,

    // setCode,
    addReceiptWithFiles,
    addFilesToReceipt,
    removeFileFromReceipt,

    isBurningCode,
    burnCode,

    codeDetails,
    // codeIsLoaded,

    isResultDiscovered,
    discoverResult,
    discoverResultAfterSomeTime,

    makeSureCodeIsLoadedOrRestart,

    updateUserInfoFromAuth,

    giftName,
    giftIsSingular,
    stepValue,

    userTitle,
    userLastName,
    userFirstName,
    userEmail,
    userPhoneNumber,

    userAddressName,
    userAddress1,
    userAddress2,
    userPostalCode,
    userCity,

    userAddressId,
    saveAddressOnProfile,
    updateAddressDetails,

    goToStep1,

    isParticipationUncomplete,

    isSubmittingParticipation,
    submitParticipation,
    isParticipationCompleted,
  }
})

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useStoreGame3, import.meta.hot))
}
