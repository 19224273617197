import { DateTime } from 'luxon'

const startDateTime = DateTime.fromISO(GAME_3_START_DATETIME)
const endDateTime = DateTime.fromISO(GAME_3_END_DATETIME)

export default function () {
  const hasStarted = ref(false)
  const isDiscontinued = ref(false)
  const isRunning = computed(() => hasStarted.value && !isDiscontinued.value)

  function update() {
    const now = DateTime.now()
    hasStarted.value = now >= startDateTime
    isDiscontinued.value = now > endDateTime

    if (isDiscontinued.value) {
      clearInterval(interval)
    }
  }
  const interval = setInterval(update, 1000)
  update()

  return {
    hasStarted,
    isDiscontinued,
    isRunning,
  }
}
